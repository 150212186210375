<template>
  <container-wrapper-full>
    <plan-heading
      v-if="!loading"
      :id="String(planData.id)"
      :plan-name="planData.name || ''"
      :apartment-name="planData.apartment_name"
      :apartment-id="planData.apartment_id"
      :can-edit="!isUserSupervisor"
      @submit-name="changeName">
      <template #status>
        <crm-plan-info
          :is-user-supervisor="isUserSupervisor"
          :is-merged="Boolean(planData.merged_from_plan_id)"
          :plan="plan"
          :tags-list="tagsList"
          :can-change-ninja="canChangeNinja"
          :options-tags="true"
          :plan-data="planData"
          :attach-tags-to-plan="attachTagsToPlan"
          :unreal-rtx-preset="plan?.plan?.options?.unreal_rtx_preset"
          route-for-crm-plan="/crm/plans/"
          @change-ninja-success="loadData" />
      </template>

      <template #actions>
        <actions-buttons
          :buttons="buttons"
          @button-click="onButtonClick" />
      </template>
    </plan-heading>

    <section
      v-if="!loading"
      id="plan-main">
      <a-row :gutter="[16,16]">
        <a-col
          :md="8"
          :span="24">
          <image-carousel>
            <template #images>
              <div
                v-for="(item, index) in planData.pictures"
                :key="item.id">
                <a-card>
                  <img
                    :style="{ width: '100%', objectFit: 'contain', marginBottom: '2rem', height: '300px' }"
                    :src="item"
                    alt="">
                  <a-card-meta :title="`${index + 1}/${planData.pictures.length} этаж`" />
                </a-card>
              </div>
            </template>
          </image-carousel>
        </a-col>

        <a-col
          :md="16"
          :span="24">
          <a-row :gutter="[16,16]">
            <a-col
              v-if="isSamePlansAiVisible"
              :span="24">
              <a-button
                :disabled="planData.pictures.length > 1 || !planData.pictures"
                :style="{ width: '100%' }"
                @click="openSamePlansAiModal">
                Поиск похожих планировок
                <a-tag
                  color="#f50"
                  :style="{marginLeft: '.5rem'}">
                  Beta
                </a-tag>
                <a-tooltip
                  v-if="plan.pictures.length > 1"
                  placement="right">
                  <template #title>
                    <span>Работает только с одноэтажными планировками</span>
                  </template>
                  <question-circle-outlined style="margin-left: 5px" />
                </a-tooltip>
              </a-button>
            </a-col>
            <a-col
              :span="24"
              :md="12">
              <a-button
                :disabled="!planData.pictures"
                :style="{ width: '100%' }"
                @click="openCompareModal">
                Открыть сравнение
              </a-button>
            </a-col>
            <a-col
              :span="24"
              :md="12">
              <a-button
                :style="{ width: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }"
                @click="downloadGluedImage">
                Скачать результат (многоэтажная обработка)
              </a-button>
            </a-col>
            <a-col
              v-if="!isUserSupervisor"
              :span="24"
              :md="12">
              <a-button
                :style="{ width: '100%' }"
                :href="planData.links.neural.editor"
                target="_blank"
                rel="noopener noreferrer">
                Открыть Конструктор
              </a-button>
            </a-col>

            <a-col
              v-if="isUserAdmin && planData.plan_id"
              :span="24"
              :md="12">
              <router-link
                :to="{ name: 'plans-show', params: { id: planData.plan_id } }">
                <a-button :style="{ width: '100%' }">
                  [Сервис] Открыть планировку
                </a-button>
              </router-link>
            </a-col>
            <a-col
              v-if="isUserAdmin || isUserNinja"
              :span="24"
              :md="12">
              <a-button
                :style="{ width: '100%' }"
                @click="openModal">
                Поиск похожих планировок
              </a-button>
            </a-col>
            <a-col
              :span="24"
              :md="12">
              <merge-crm-plan-button
                :current-plan-id="planData.id"
                :type="type"
                text-button="Слияние планировки"
                @after-ok="loadData" />
            </a-col>
            <a-col
              v-if="publicPlanUrl"
              :span="24">
              <a-button
                :style="{ width: '100%' }"
                :href="publicPlanUrl"
                target="_blank"
                rel="noopener noreferrer">
                Открыть публичную ссылку
              </a-button>
            </a-col>
          </a-row>

          <div style="overflow: auto">
            <a-divider>Описание</a-divider>
            <div v-if="!isEditModeEnabled">
              <span>
                {{ plan.description || "Нет описания" }}
              </span>
              <form-outlined
                :style="{ color: '#1890ff', marginLeft: '1rem' }"
                @click="setEditMode(true)" />
            </div>

            <form v-if="isEditModeEnabled">
              <a-space align="center">
                <a-textarea
                  v-model:value="newPlanDescription"
                  placeholder="Описание планировки"
                  :style="{ maxWidth: '100%' }" />
                <check-circle-outlined
                  :style="{ color: '#52c41a', marginLeft: '1rem' }"
                  @click="changeDescription" />
                <close-circle-outlined
                  :style="{ color: '#f5222d', marginLeft: '1rem' }"
                  @click="cancelEditPlanDescription" />
              </a-space>
            </form>
          </div>

          <div v-if="plan.apartment">
            <plan-apartment-description
              :plan-apartment="plan.apartment" />
          </div>

          <div v-if="plan.references.length > 0 && isPlanDescriptionAndRendersVisible">
            <a-divider>Референсы</a-divider>
            <image-viewer
              :style="{display: 'flex', gap: '20px', flexWrap: 'wrap'}"
              :images="plan.references">
              <div
                v-for="(item) in plan.references"
                :key="item.id">
                <img
                  :style="{'object-fit': 'contain'}"
                  :src="item"
                  loading="lazy"
                  height="100"
                  width="100"
                  alt="References">
              </div>
            </image-viewer>
          </div>
        </a-col>
      </a-row>
    </section>

    <a-skeleton
      v-if="loading"
      active
      :paragraph="{ rows: 7 }" />

    <section
      v-if="!loading && (planData.links.svg_editor.jpg
        || planData.links.svg_editor.svg
        || planData.links.svg_editor.svg_layout || planData.links.svg_editor.jpg_layout)"
      id="svg-editor-result">
      <a-divider type="horizontal" />
      <crm-plan-2d-results :links="plan?.links?.svg_editor" />
    </section>

    <section v-if="planData.apartment_id">
      <a-row :gutter="[16,16]">
        <a-col
          v-if="planData.apartment_id"
          :span="24">
          <loaded-files-list
            :files="planData.apartment_files"
            :files-sum="planData.apartment_files?.length"
            :loading="isSourcesArchiveCreating"
            @download="downloadSources" />
        </a-col>

        <a-col
          v-if="planData.apartmentLinkedPlans.length !== 0"
          :span="24">
          <linked-plans
            :data="planData.apartmentLinkedPlans"
            :type="0"
            @change="null" />
        </a-col>
      </a-row>
    </section>

    <a-row
      type="flex"
      align="top"
      :style="{ gap: '1rem' }">
      <plan-status-logs
        :id="planId"
        :type="type" />

      <plans-comments
        :id="planId"
        :type="type"
        :auth="authUser" />
    </a-row>

    <change-ninja-modal
      :plan-id="planData.id"
      action="crm/changeCrmPlanNinja" />
  </container-wrapper-full>

  <a-modal
    :open="isCompareModalVisible"
    :destroy-on-close="true"
    :footer="null"
    title="Сравнение"
    centered
    :style="{ width: '100%', top: '8px' }"
    @cancel="closeCompareModal">
    <plan-compare-carousel
      :originals="compareCarousel.originals"
      :svgs="compareCarousel.svgs"
      :top-views="compareCarousel.topViews" />
  </a-modal>

  <a-modal
    v-if="samePlansVisible"
    v-model:open="samePlansVisible"
    :footer="null"
    title="Поиск похожих планировок"
    width="100%"
    wrap-class-name="full-modal"
    @close="closeModal">
    <same-plans-list
      :plan-data="planData"
      :type="type"
      :plan-image="planData.pictures"
      @close="closeModal"
      @update-page="loadData" />
  </a-modal>

  <a-modal
    v-if="isSamePlansAiModalVisible"
    v-model:open="isSamePlansAiModalVisible"
    :footer="null"
    width="100%"
    wrap-class-name="full-modal"
    @close="closeSamePlansAiModal">
    <template #title>
      Поиск похожих планировок
      <a-tag
        color="#f50">
        Beta
      </a-tag>
    </template>
    <same-plans-ai-list
      :plan-type="type"
      :plan-data="planData"
      :original-plan-img="plan?.picture"
      :plan-image="planData?.pictures"
      @close="closeSamePlansAiModal" />
  </a-modal>
</template>

<script setup>
import {
  computed, onMounted, ref, watchEffect,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import config from '@/config'; // eslint-disable-line import/extensions
import {
  QuestionCircleOutlined, FormOutlined, CheckCircleOutlined, CloseCircleOutlined,
} from '@ant-design/icons-vue';

import { PlanType } from '@/enums/planType';
import { Role } from '@/enums/roles';

import { message } from 'ant-design-vue';

import { getStatusTag } from '@/enums/PlanStatusEnum';

import PlansComments from '@/Components/CRM/Plans/Comments.vue';
import ContainerWrapperFull from '@/Components/Common/ContainerWrapperFull.vue';
import PlanHeading from '@/Components/Service/Plans/PlanHeadingWrapper.vue';
import PlanStatusLogs from '@/Components/CRM/Plans/StatusLogs.vue';
import ChangeNinjaModal from '@/Components/CRM/Plans/Modals/ChangeNinjaModal.vue';
import ImageViewer from '@/Components/Common/ImageViewer.vue';
import { executeAction, getPlanActionsForUser } from '@/composables/planActions';
import CrmPlanInfo from '@/Components/CRM/Plans/CrmPlanInfo.vue';
import LoadedFilesList from '@/Components/CRM/Apartments/LoadedFilesList.vue';
import MergeCrmPlanButton from '@/Components/CRM/Plans/MergeCrmPlanButton.vue';
import SamePlansList from '@/Components/CRM/Plans/SamePlansList.vue';
import ActionsButtons from '@/Components/CRM/Plans/ActionsButtons.vue';
import CrmPlan2dResults from '@/Components/CRM/Plans/CrmPlan2dResults/CrmPlan2dResults.vue';
import LinkedPlans from '@/Components/CRM/Apartments/LinkedPlans.vue';
import { httpClient } from '@/httpClient';
import PlanCompareCarousel from '@/Components/CRM/Plans/CompareCarousel.vue';
import PlanApartmentDescription from '@/Components/CRM/Plans/PlanApartmentDescription.vue';
import { getFilesAsNameAndUrl } from '@/utils/getFilesAsNameAndUrl';
import SamePlansAiList from '@/Components/CRM/Plans/SamePlansAiList.vue';
import ImageCarousel from '@/Components/Common/ImageCarousel.vue';
import getFlag from '@/helpers/getFlag';

const setPlanData = (data) => ({
  crm_tags: data.crm_tags,
  crmTagsModified: data.crm_tags.map((tag) => tag.id),
  id: data.id,
  name: data.name,
  status: getStatusTag(data.status),
  ninja: data.ninja,
  cutter: data.cutter,
  manager: data.manager,
  pictures: data.pictures,
  links: data.links,
  description: data.description,
  plan_id: data.plan_id,
  apartment_id: data.apartment?.id || null,
  apartment_name: data.apartment?.name || null,
  apartment_files: getFilesAsNameAndUrl(data.apartment?.files || []),
  apartment_description: data.apartment?.description || null,
  apartmentLinkedPlans: data.apartment?.linked_plans?.filter((row) => row.includes(data.id)) || [],
  address: data.address,
  complex: data.complex,
  developer: data.developer,
  region: data.region,
  merged_from_plan_id: data.merged_from_plan_id,
});

const props = defineProps({
  id: {
    type: Number,
    default: () => null,
  },
});

const router = useRouter();
const route = useRoute();
const store = useStore();

const planData = ref({});

const planId = computed(() => Number(props.id || route.params.id));

const buttons = ref([]);

const loading = ref(true);

const isCompareModalVisible = ref(false);

const samePlansVisible = ref(false);

const plan = computed(() => store.state.crm.currentPlan);

const authUser = computed(() => store.state.auth.currentUser);

const authUserRoles = computed(() => store.state.auth.currentUser.roles.map(({ name }) => name.toLowerCase()));

const isUserSupervisor = computed(() => authUserRoles.value.includes('supervisor'));
const isUserAdmin = computed(() => authUserRoles.value.includes('admin'));
const isUserNinja = computed(() => authUserRoles.value.includes('ninja'));

const isPlanDescriptionAndRendersVisible = getFlag('isPlanDescriptionAndRendersVisible');

const isSamePlansAiVisible = getFlag('isSamePlansAiVisible');

const publicPlanUrl = computed(() => {
  const servicePlanId = planData.value?.plan_id;
  const { publicUrl } = config;
  if (!servicePlanId || !publicUrl) {
    return null;
  }

  return `${publicUrl}/${servicePlanId}`;
});

const closeModal = () => {
  samePlansVisible.value = false;
};

const openModal = () => {
  samePlansVisible.value = true;
};

const loadData = () => {
  loading.value = true;
  return store
    .dispatch('crm/getCrmPlanById', planId.value)
    .then((resp) => {
      planData.value = setPlanData(resp);
      buttons.value = getPlanActionsForUser(PlanType.Type2d, planData.value, authUser.value);
    })
    .finally(() => (loading.value = false));
};

watchEffect(planId, (newId) => {
  if (newId && route.name === 'crm-plans-show') {
    loadData();
  }
  if (samePlansVisible.value) {
    closeModal();
  }
});

const changeName = (name) => {
  store.dispatch('crm/changePlanName', { id: planId.value, name }).finally(loadData);
};

const attachTagsToPlan = (tagsIds) => {
  loading.value = true;
  store.dispatch('crm/attachTagsToPlan', { id: planId.value, tagsIds }).finally(loadData);
};

const onButtonClick = (action) => {
  executeAction(action, planId.value, PlanType.Type2d, loadData, router);
};

onMounted(() => {
  loadData();
  store.dispatch('crm/getTagsList');
  store.dispatch('crm/getSimpleUsersList');
});

const openCompareModal = () => (isCompareModalVisible.value = true);
const closeCompareModal = () => {
  isCompareModalVisible.value = false;
};

const compareCarousel = computed(() => ({
  originals: plan.value.pictures,
  svgs: plan.value.links.svg_editor.jpg,
  topViews: [],
}));

const tagsList = computed(() => store.state.crm.tagsList);
const type = PlanType.Type2d;

const canChangeNinja = computed(() => {
  const currentUserHasRoles = store.getters['auth/currentUserHasRoles'];

  if (currentUserHasRoles(Role.Admin)) {
    return true;
  }

  if (!currentUserHasRoles(Role.Manager)) {
    return false;
  }

  return store.state.crm.currentPlan.manager?.id === store.state.auth.currentUser.id;
});

const isSourcesArchiveCreating = computed(() => store.state.crm.apartments.isSourcesArchiveCreating);
const downloadSources = () => store.dispatch('crm/apartments/downloadSourcesArchive', planData.value?.apartment_id);

const downloadGluedImage = () => {
  // TODO: Нормально сделать, Нужен рефакторинг
  const messageKey = `download-glued-image-${Date.now()}`;
  message.loading({
    duration: 0,
    content: 'Генерируем изображение',
    key: messageKey,
  });

  httpClient.get(`/api/crm/plans/${planData.value.id}/get_glued_picture`)
    .then(({ data }) => window.open(data.url))
    .catch(() => message.error('Что-то пошло не так :('))
    .finally(() => message.destroy(messageKey));
};

const isSamePlansAiModalVisible = ref(false);

const openSamePlansAiModal = () => {
  isSamePlansAiModalVisible.value = true;
};

const closeSamePlansAiModal = () => {
  isSamePlansAiModalVisible.value = false;
};

const isEditModeEnabled = ref(false);
const newPlanDescription = ref('');

const setEditMode = (value) => {
  if (value) {
    newPlanDescription.value = planData.value.description || '';
  }
  isEditModeEnabled.value = value;
};

const cancelEditPlanDescription = () => setEditMode(false);

const changeDescription = () => {
  store.dispatch('crm/changePlanDescription', { id: planId.value, description: newPlanDescription.value })
    .finally(() => {
      setEditMode(false);
      loadData();
    });
};

</script>

<style>
.ant-timeline-item-last {
  padding-bottom: 5px;
}

.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 0;
}

.status-history__user {
  display: block;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#plan-main {
  align-items: flex-start;
}

.plan-main-image {
  width: calc(100% / 3);
}

@media (max-width: 767px) {
  .plan-main-image {
    width: 100%;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.ant-modal {
  top: 0 !important;
  max-width: 100% !important;
}

</style>
