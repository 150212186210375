export const BuildPriority = {
  Normal: 3,
  High: 2,
};

export const BuildPriorityOptionNames = {
  [BuildPriority.Normal]: 'Обычный приоритет',
  [BuildPriority.High]: 'Высокий приоритет',
};

export const BuildPriorityOptions = Object.entries(BuildPriorityOptionNames)
  .map(([value, label]) => ({ value, label }));
